
.app__skills {
	width: 100%;
	height: 100%;

	display: flex;
	flex-direction: row;
	justify-content: center;
	flex-wrap: wrap;
	align-items: center;
	gap: 2rem;

	padding: 2rem 4rem 2rem;

	background: white;

	@media screen and (max-width: 900px) {
		padding: 2rem 2rem 4rem;
	}
}

.app__skills-main {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: min-content;

	div {
		display: flex;


		justify-content: center;
	}

	h1 {
		color: var(--secondary-color);
		font-weight: bold;
	}

	@media screen and (max-width: 600px) {
		width: 100%;
	}
}

.app__skill {
	padding: 1rem;
	display: flex;
	flex-wrap: wrap;
	flex-direction: column;


	img {
		width: 50px;

		padding-bottom: .5rem;
		border-bottom: 3px solid transparent;

		&:hover {
			scale: 1.1;
			cursor: pointer;
		}
	}
}

.app__skill-active {
	transition: 1000ms;

	border-bottom: 3px solid var(--secondary-color) !important;
	scale: 1.1;
}


.app__skills-skill-group {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;


	p {
		text-align: center;
		text-align: justify;
		color: var(--gray-color);
		width: 100%;
		min-height: 90px;
	}

	@media screen and (max-width: 600px) {
		div {
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
		}
	}
}