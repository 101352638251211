#home {
  position: relative;
  /*background: url('../../assets/temp-background\ old1.jpg');
  background-repeat: none;
  background-size: cover;
  background-attachment: fixed;
  background-position: bottom;*/
  z-index: 1;


}

.app__header {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  gap: 1.5rem;

  width: 100%;
  height: 100%;
  padding: 6rem 2rem 0;

  div:is(:first-child) {
    margin-top: -6rem;
  }

  div {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;

    h1 {
      font-size: 5rem;
      color: var(--secondary-color);
    }
  
    p {

      font-style: italic;
      color: var(--gray-color);
    }

    @media screen and (max-width: 500px) {
      h1 {
        font-size: 4rem;
      }
    } 

    @media screen and (max-width: 300px) {
      h1 {
        font-size: 3rem;
      }
    } 
  }

  img {
    width: 400px;
    color: var(--secondary-color);
    margin-top: -2rem;
  }

  @media screen and (max-width: 1200px) {
    flex-direction: column;

    img {
      width: 280px;
      margin-top: 0;
    }
    
    div:is(:first-child) {
      margin-top: 0rem;
    }

    @media screen and (max-width: 300px) {
      img {
        width: 200px;
        margin-top: 0;
      }
    } 
  } 
}

.app__header-socials {
  ul {
    flex-direction: row;
    flex-wrap: wrap;
    list-style: none;

    margin: 1rem 0 0 0;

    li {
      margin: 0 .5rem 0;
      font-weight: bold;
      transition: all 0.3s ease-in-out;

      &:hover {
        cursor: pointer;
      }

      a {
        text-decoration: none;
        text-transform: uppercase;

        color: var(--gray-color);

        transition: all 0.3s ease-in-out;

        &:hover {
          color: var(--secondary-color);
        }
      }
    }

    @media screen and (max-width: 300px) {
      flex-direction: column;

      li {
        padding-bottom: .5rem;
      }
    } 
  }
}