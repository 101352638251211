
.app__about {
	margin-top: -4rem;
	position: relative;
	height: 100%;
	padding: 2rem 4rem 2rem;
	background: white;

	h1 {
		color: var(--secondary-color)
	}

	p {
		color: var(--gray-color)
	}

	@media screen and (max-width: 900px) {
		padding: 6rem 2rem 4rem;
	}
}

.app__about-tile {
	display: flex;
	flex-direction: row;
	max-width: 1400px;

	background-color: var(--primary-color);
	box-shadow: rgba(0, 0, 0, 0.2) 0px 5px 15px;
	border-radius: 15px;

	h1 {
		color: var(--secondary-color)
	}

	p {
		color: var(--gray-color);
		font-size: large;
	}

	@media screen and (max-width: 900px) {
		flex-direction: column !important;

		p {
			font-size: medium;
		}
	}
}

.app__about-text {

	display: flex;
	justify-content: center;
	flex-direction: column;
	gap: 1rem;

	text-align: justify;
}

.app__about-hobbies {
	display: flex;
	flex-direction: row;

	img {
		max-width: 500px;
		scale: 1 to 1;
	}

	div {
		display: flex;
		flex-direction: column;
	}
}

.app__about-image {
	img {
		object-fit: cover;
		object-position: center;
		min-width: 100%;
		min-height: 100%;
		max-width: 400px;
		max-height: 400px;
		width: auto;
		height: auto;

		@media screen and (max-width: 900px) {
			max-width: 500px;
			max-height: auto;
		}
	}
}

.img-border-left {
	//border-radius: 0 0 0 0;
	border-top-left-radius: 15px;
	border-bottom-left-radius: 15px;
}

.img-border-right {
	border-top-right-radius: 15px;
	border-bottom-right-radius: 15px;
}


/*
div {
	display: flex;
	
	img {
		max-width: 700px;
		border-top-left-radius: 15px;
		border-bottom-left-radius: 15px;
	}*/

	.img-display-left {
		flex-direction: row;
	}

	.img-display-right {
		flex-direction: row-reverse;
	}
	
.app__about-hobbies {
	display: flex;
	flex-direction: row;
	gap: 2rem;

	div {
		text-align: justify;
	}
}