.app {
  background-color: var(--primary-color);
  font-family: var(--font-base);
}

.app__whitebg {
  background-color: var(--white-color);
}

.app__primarybg {
  background-color: var(--primary-color);
}

.app__container {
  width: 100%;
  //min-height: 100vh;

  display: flex;
  flex-direction: row;
}

.app__flex {
  display: flex;
  justify-content: center;
  align-items: center;
}

.app__wrapper {
  flex: 1;
  width: 100%;
  flex-direction: column;
  padding: 4rem 2rem;

  @media screen and (max-width: 450px) {
    padding: 4rem 1rem 2rem;
  }
}

.app__navigation {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  padding: 1rem;

  .app__navigation-dot {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #cbcbcb;
    margin: 0.5rem;

    transition: background-color 0.2s ease-in-out;

    &:hover {
      background-color: var(--secondary-color);
    }

    @media screen and (min-width: 2000px) {
      width: 20px;
      height: 20px;
    }
  }
}

@media screen and (max-width: 500px) {
  .app__navigation,
  .app__social {
    display: none;
  }

  .copyright {
    padding: 2rem;
  }
}

a.about-anchor {
  display: block;
  position: relative;
  top: -160px;
  visibility: hidden;

  @media screen and (max-width: 900px) {
    top: -200px;

    @media screen and (max-width: 600px) {
      top: -250px;
    }
    
    @media screen and (max-width: 400px) {
      top: -300px;
    }  
  }  
}


a.anchor {
  display: block;
  position: relative;
  top: -90px;
  visibility: hidden;
}

a.skills-anchor {
  display: block;
  position: relative;
  top: -180px;
  visibility: hidden;
}

.experience-anchor {
  display: block;
  position: relative;
  top: -50px;
  visibility: hidden;
}