#contact {
	width: 100%;

	h1 {
		color: var(--secondary-color);
	}
}

.app__contact-name {
	display: flex;
	flex-direction: column;
	width: 100%;
	gap: 2rem;
	margin: 4rem;

	div {
		width: 100%;
		display: flex;
		flex-direction: column;
		
		input {
			width: 100%;
			height: 30px;
			
		}
	}
}