.projects {
	padding: 2rem 4rem 4rem;
	display: flex;
	flex-direction: column;

	@media screen and (max-width: 900px) {
		padding: 2rem 2rem 4rem;
	}
}

.app__projects-title {
	width: 100%;
	text-align: center;

	h1 {
		color: var(--secondary-color);
		text-align: left;
	}
}

.app__projects-tiles {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;

	padding: 2rem 0 0;
	gap: 1.5rem;

	h2 {
		color: var(--secondary-color);
		text-align: left;
	}

	p {
		color: var(--gray-color);
	}

	@media screen and (max-width: 1100px) {
		flex-direction: column;
	}
}

.app__projects-tile {
	display: flex;
	flex-direction: column;
	gap: 1rem;
	background-color: var(--white-color);
	flex: 1;
	min-width: 40%;

	padding-bottom: 1rem;
	box-shadow: rgba(0, 0, 0, 0.2) 0px 5px 15px;
}

.app__projects-tile-img {
	img {
		width: 100%;
		height: 300px;
		object-fit: cover;
	}

	div {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		gap: 1rem;
		margin-top: -.75rem;

		div {

			cursor: pointer;
			justify-content: space-between;
			align-items: center;

			div {
				height: 10px;
				width: 10px;
				background-color: white;
				border-radius: 50%;
				outline: 2px solid var(--gray-color);
			}

			&:hover {
				div {
					scale: 1.1;
					background-color: var(--secondary-color);
				}
			}
		}
	}
}

.active-img {
	scale: 1.1;
	div {

		background-color: var(--secondary-color) !important;
	}
}

.app__projects-tile-text {
	margin: 0 1rem 1rem;
	text-align: justify;
}

.app__projects-tile-header {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}


.app__projects-tile-icons {
	display: flex;
	flex-direction: row;
	gap: 1rem;

	img {
		width: 50px;
		height: 50px;
		object-fit: contain;
		border-radius: 50%;
	}
}

.github-icon {
	display: flex;
	align-items: center;
	justify-content: center;
	
	cursor: pointer;

	a {
		color: black;

		&:hover {
			color: var(--secondary-color);
		}
	}
}