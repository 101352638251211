
.app__experience-header {
  h1 {
    color: var(--secondary-color);
    text-align: center;
    width: 100%;
    padding: 2rem 4rem 0;
  }
}

.app__experience {
  display: flex;
  justify-content: center;
  align-items: stretch;
  gap: 1.5rem;
  
  width: 100%;
	height: 100%;
	flex-direction: row;
	padding: 2rem 4rem 2rem;
  flex-wrap: wrap;

  @media screen and (max-width: 900px) {
		padding: 2rem 2rem 2rem;
	}
}

.app__experience-tile {
	background: white;
	max-width: 400px;
	border-radius: 15px;
	box-shadow: rgba(0, 0, 0, 0.2) 0px 5px 15px;
  color: var(--graycolor);
  padding-bottom: .5rem;

	ul {
		display: flex;
    flex-direction: column;
    padding-left: 3rem;
    padding-top: 1rem;

    li {
      color: var(--gray-color);
      padding-right: 2rem;
      padding-bottom: .5rem;
    }
	}

  @media screen and (max-width: 1000px) {
		max-width: 350px;
	}

  @media screen and (max-width: 800px) {
		width: 100%;
    max-width: none;
	}
}

.app__experience-tile-header {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  
  text-align: end;
  padding: 1rem 1rem;


  h2 {
    color: var(--secondary-color)
  }

  img {
    margin-left: 1rem;
    border-radius: 15px;
    width: 80px;
  }
}

.app__experience-tile-text {
  display: flex;
  flex-direction: column;


  div {
    padding-left: 1rem;

    h3 {
      padding-bottom: .5rem;
    }
  }
}

.seperator {
  border-top: 3px solid var(--secondary-color);
  width: 100px;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 1rem;
}